/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

@import "@core/scss/base/components/include";

body {
  // user-select: none;
}
.list-item {
  margin: 10px 0px;
  .key {
    &:after {
      content: ":";
    }
    margin-right: 5px;
    color: #777;
  }
  .value {
    font-weight: 500;
  }
}
.border-container {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 15px;
  margin: 10px;
  .container-title {
    font-size: 15px;
    margin-bottom: 5px;
    font-weight: 500;
  }
  .container-content {
    margin-left: 20px;
  }
}

.singleCard {
  max-width: 60%;
  min-width: 25%;
  margin-left: calc(42px + 5px + 0.5em);
  margin-bottom: 1em;

  &.address {
    max-width: calc(100% - 5px - 3em - 0.5em - 3em - 5px);
  }
}

.imgcard {
  width: 100%;
  border-radius: 10px;
}

.suggestions {
  padding: 10px;
  border-radius: 0.5rem;
  background: #ffffff;
  box-shadow: 2px 0.25rem 0.25rem 1px #dbdade;
}

.menuTitle {
  padding: 0.25rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.menuChips {
  display: block;
  background: $kai-blue;
  color: #fff;
  text-align: center;
  padding: 0.25rem 0.75rem;
  margin-bottom: 0.25rem;
  cursor: pointer;
  border-radius: 0.25rem;
  font-size: 13px;
  word-wrap: break-word;

  // badge number styles
  position: relative;
  .menu-chips-badge {
    position: absolute;
    top: -0.5rem;
    right: -0.5rem;
  }
}

.menuChips:last-of-type {
  margin-bottom: 0;
}

.menuCheckChips {
  margin: 0.5rem;
}

.primary-btn {
  background: $kai-blue;
  color: #fff;
  padding: 0.25rem;
  margin-bottom: 0.25rem;
  cursor: pointer;
  border-radius: 0.25rem;
  font-size: 13px;
  word-wrap: break-word;

  width: 100%;
  border: none;
}

.primary-btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

#upload-error-text {
  word-break: break-word;
}

.userAvatar {
  animation: animateElement linear 0.3s;
  animation-iteration-count: 1;
  border-radius: 50%;

  width: 3em;
  height: 3em;
  float: right;
  margin-right: 5px;
}

.userMsg {
  animation: animateElement linear 0.2s;
  animation-iteration-count: 1;

  margin-right: 5px;
  word-wrap: break-word;
  padding: 10px;
  float: right;
  background: $kai-pink;
  color: white;
  font-size: 13px;
  max-width: 60%;
  min-width: 15%;
  box-shadow: 0px 2px 5px 0px #9a828454;
  background-repeat: repeat-x;
  border-radius: 0.357rem;
  margin-bottom: 1rem;
  line-height: 1.5rem;
}

.botMsg {
  float: left;
  margin-top: 5px;
  background: white;
  color: $kai-blue;
  box-shadow: 0 4px 8px 0 rgb(34 41 47 / 12%);
  margin-left: 0.5em;
  padding: 10px;
  max-width: 60%;
  min-width: 25%;
  font-size: 13px;
  word-wrap: break-word;
  border-radius: 0.357rem;
  white-space: pre-wrap;
  margin-bottom: 1rem;
  line-height: 1.5rem;
}

.botAvatar {
  border-radius: 50%;
  width: 3em;
  height: 3em;
  float: left;
  margin-left: 5px;
}

#file-drop-zone {
  width: 100%;
  min-height: 100px;
  background-color: $gray-200;
  border: 1px dashed $kai-blue;
  border-radius: 5px;
  margin: 1rem 0rem;
  padding: 1rem;
  text-align: center;

  &.active {
    background-color: $gray-100;
  }

  #file-upload-icon {
    font-size: 4rem;
  }
}

#cancel-btn-div {
  display: none;
  text-align: center;
  position: absolute;
  width: 100%;
  top: calc(50% + 80px);
  opacity: 1;
  z-index: 1000;
}

#cancel-request-btn {
  width: 50%;
}

.form-check {
  .form-check-label {
    word-break: break-all;
  }
}

#addressPickerContainer {
  position: relative;

  input {
    &:focus {
      border-bottom: 1px solid #0a2746;
      -webkit-box-shadow: 0 1px 0 0 #0a2746;
      box-shadow: 0 1px 0 0 #0a2746;
    }
  }

  .prefix {
    &.active {
      color: #0a2746 !important;
    }
  }
}

ul#suggestions {
  padding-inline-start: 0px;

  li {
    list-style: none;
    padding: 0.5rem 1rem;

    &:hover {
      background-color: #0a2746;
      color: #ffffff;
    }
  }
}

.botTyping > div {
  width: 10px;
  height: 10px;
  background-color: $kai-blue;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  margin-right: 5px;
  margin-top: 1rem;
}

.botTyping .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.botTyping .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

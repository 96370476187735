@import "@core/scss/core.scss";
@import "./assets/scss/styles";

@import "@core/scss/angular/libs/select.component.scss";

// to prevent overflowing-overlapping datatable cell text
.ngx-datatable {
  &.fixed-row {
    .datatable-scroll {
      .datatable-body-row {
        .datatable-body-cell {
          white-space: normal !important;
        }
      }
    }
  }
}

.form-control-checkbox {
  margin-top: 20px;
}

.block-ui-wrapper {
  border-radius: 4px !important;
  // Z index is modified because otherwise in list components like location, the loading overlay was overlapping with side menu in small screens
  z-index: 99 !important;
}

.form-group {
  &.required {
    .control-label {
      &:after {
        content: "*";
        color: red;
      }
    }
  }
}
a {
  &.hasError {
    color: red !important;
    font-weight: 500;
  }
}

ng-select {
  &.ng-invalid {
    .ng-select-container {
      // padding-right: calc(1.45em + 0.876rem) !important;
      // background-image: url(
      //   data:image/svg + xml,
      //   %3csvgxmlns="http://www.w3.org/2000/svg"width="12"height="12"fill="none"stroke="%23ea5455"viewBox="0 0 12 12"%3e%3ccirclecx="6"cy="6"r="4.5"/%3e%3cpathstroke-linejoin="round"d="M5.8 3.6h.4L6 6.5z"/%3e%3ccirclecx="6"cy="8.2"r=".6"fill="%23ea5455"stroke="none"/%3e%3c/svg%3e
      // );
      background-repeat: no-repeat;
      background-position: right calc(0.3625em + 0.219rem) center;
      background-size: calc(0.725em + 0.438rem) calc(0.725em + 0.438rem);
    }
  }
  &.is-invalid {
    .ng-select-container {
      border-color: #ea5455 !important;
    }
  }
  &.ng-select-focused {
    &.is-invalid {
      .ng-select-container {
        border-color: #ea5455 !important;
      }
    }
  }
}

table {
  // table with fixed header and tbody scrollable with 240px height
  &.table-240 {
    thead {
      display: table;
      width: 100%;
      table-layout: fixed;
      /* even columns width , fix width of table too*/

      width: calc(100% - 1em);
      /* scrollbar is average 1em/16px width, remove it from thead width */
    }

    tbody {
      display: block;
      height: 240px;
      overflow-y: scroll;
    }

    thead,
    tbody {
      tr {
        display: table;
        width: 100%;
        table-layout: fixed;
        /* even columns width , fix width of table too*/
      }
    }
  }
}

// scrollable div with fixed height of 240px
.div-240 {
  height: auto;
  max-height: 240px;
  overflow-y: auto;
}

// custom checkbox group
.custom-checkbox-group {
  height: 2.714rem;
  display: flex;
  align-items: center;
  margin: 0px !important;
}
.custom-checkbox-label {
  font-size: 1rem;
}

// Custom fieldset-legent with border start
fieldset.custom-border {
  border: 1px groove #ddd !important;
  padding: 0 1.4em 1.4em 1.4em !important;
  margin: 0 0 1.5em 0 !important;
  -webkit-box-shadow: 0px 0px 0px 0px #000;
  box-shadow: 0px 0px 0px 0px #000;
}

legend.custom-border {
  font-size: 1.2em !important;
  font-weight: bold !important;
  text-align: left !important;
  width: auto;
  padding: 0 10px;
  border-bottom: none;
}
// Custom fieldset-legent with border end

// custom toggle start
.custom-view-toggle:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: $kai-pink;
  background-color: $kai-pink;
}
// custom toggle end

.word-break-all {
  word-break: break-all;
}

//
// custom toggle switch style
//
@import "@core/scss/base/colors";

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 6px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: $primary;
}

input:focus + .slider {
  box-shadow: 0 0 1px $primary;
}

input:checked + .slider:before {
  -webkit-transform: translateX(21px);
  -ms-transform: translateX(21px);
  transform: translateX(21px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 25px;
}

.slider.round:before {
  border-radius: 50%;
}
//
// custom toggle switch style
//
.download-enabled {
  pointer-events: auto !important;
}

// shepher tour styles
.shepherd-header {
  background: $primary !important;
  color: #ffffff !important;

  h3 {
    color: #ffffff !important;
  }
}

.shepherd-button {
  &.btn-outline-primary {
    border: 1px solid $primary;
    background: transparent !important;
    color: $primary !important;
  }
}
// shepher tour styles

.time-range-container {
  display: flex;
  gap: 1rem;
  align-items: center;

  ng-select {
    width: 50%;
  }
}
